// TODO don't need to prefix "INTERFACE"

export const INTERFACE_SHOW_LOGIN_DIALOG = 'INTERFACE_SHOW_LOGIN_DIALOG';
export const INTERFACE_HIDE_LOGIN_DIALOG = 'INTERFACE_HIDE_LOGIN_DIALOG';

export const REGISTRATION_NEXT_ACTIVE_STEP = 'REGISTRATION_NEXT_ACTIVE_STEP';
export const REGISTRATION_PREVIOUS_ACTIVE_STEP =
  'REGISTRATION_PREVIOUS_ACTIVE_STEP';

export const RESTORE_INTERFACE_DEFAULTS = 'RESTORE_INTERFACE_DEFAULTS';

export const SNACKBAR_OPEN = 'SNACKBAR_OPEN';
export const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE';

export const SET_LOADING_MESSAGE = 'SET_LOADING_MESSAGE';

export const TOGGLE_SHOW_HELP = 'TOGGLE_SHOW_HELP';

export const SHOW_CHANGE_PASSWORD_DIALOG = 'SHOW_CHANGE_PASSWORD_DIALOG';
export const HIDE_CHANGE_PASSWORD_DIALOG = 'HIDE_CHANGE_PASSWORD_DIALOG';

export const SHOW_CHANGE_EMAIL_DIALOG = 'SHOW_CHANGE_EMAIL_DIALOG';
export const HIDE_CHANGE_EMAIL_DIALOG = 'HIDE_CHANGE_EMAIL_DIALOG';

export const COPY_TEXT_TO_CLIPBOARD = 'COPY_TEXT_TO_CLIPBOARD';

export const SET_SHOW_CART = 'SET_SHOW_CART';

export const WINDOW_RESIZE = 'WINDOW_RESIZE';
