export const RETRIEVE_SUBMISSIONS_BY_USER = 'RETRIEVE_SUBMISSIONS_BY_USER';
export const RETRIEVE_ALL_SUBMISSIONS = 'RETRIEVE_ALL_SUBMISSIONS';
export const SET_RETRIEVE_ALL_SUBS_REQUEST_STATUS = 'SET_RETRIEVE_ALL_SUBS_REQUEST_STATUS';
export const STORE_SUBMISSIONS = 'STORE_SUBMISSIONS';

export const ADD_SUBMISSION_COMMENT = 'ADD_SUBMISSION_COMMENT';
export const RETRIEVE_SUBMISSION_COMMENT_HISTORY =
  'RETRIEVE_SUBMISSION_COMMENT_HISTORY';
export const STORE_SUBMISSION_COMMENTS = 'STORE_SUBMISSION_COMMENTS';

export const UPLOAD_SUBMISSION = 'UPLOAD_SUBMISSION';

export const SET_SUBMISSION_PHASE = 'SET_SUBMISSION_PHASE';

export const SET_SUBMISSION_COMMENT_HISTORY_RETRIEVAL_STATE =
  'SET_SUBMISSION_COMMENT_HISTORY_RETRIEVAL_STATE';

export const RETRIEVE_MOST_RECENT_FILE = 'RETRIEVE_MOST_RECENT_FILE';

export const STORE_SUBMISSION_FILE = 'STORE_SUBMISSION_FILE';
export const CLEAR_SUBMISSION_FILE = 'CLEAR_SUBMISSION_FILE';

export const CHECK_SUBMISSION_OPTIONS_AND_STORE_FILE =
  'CHECK_SUBMISSION_OPTIONS_AND_STORE_FILE';

export const SET_UPLOAD_STATE = 'SET_UPLOAD_STATE';

export const DOWNLOAD_MOST_RECENT_FILE = 'DOWNLOAD_MOST_RECENT_FILE';

export const DATA_SUBMISSION_SELECT_OPTIONS_FETCH =
  'DATA_SUBMISSION_SELECT_OPTIONS_FETCH';
export const DATA_SUBMISSION_SELECT_OPTION_STORE =
  'DATA_SUBMISSION_SELECT_OPTION_STORE';

export const DATA_SUBMISSION_DELETE = 'DATA_SUBMISSION_DELETE';

export const CHECK_SUBM_NAME_REQUEST_SEND = 'CHECK_SUBM_NAME_REQUEST_SEND';
export const SET_CHECK_SUBM_NAME_REQUEST_STATUS = 'SET_CHECK_SUBM_NAME_REQUEST_STATUS';
export const CHECK_SUBM_NAME_RESPONSE_STORE = 'CHECK_SUBM_NAME_RESPONSE_STORE';

export const SET_SUBM_TYPE = 'SET_SUBMISSION_TYPE';
export const SET_SUBM_ID = 'SET_SUBMISSION_ID';

export const SET_AUDIT = 'SET_AUDIT';
export const SET_WORKBOOK_AUDIT = 'SET_WORKBOOK_AUDIT';
export const SET_SHEET_AUDIT = 'SET_SHEET_AUDIT';
